<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="isCategoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="isDecodeMode" class="title">{{ getCommonText('decoding') }}. {{ this.decodeData.categoryName }}</p>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <template v-if="isCategoryMode">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad || catsHaveDoubles"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                        <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || catsHaveDoubles || !isCatDataChanged">{{ getCommonText('save') }}</b-button>
                    </template>
                    <template v-if="isDecodeMode">
                        <b-button variant="light" @click="beforeLeaveDecode(isDecodesDataChanged)" class="back-to-category-mode-btn">
                           <i class="icon icon-keyboard icon-rotate-180"></i>{{ getCommonText('back_to_list') }}
                        </b-button>
                        <b-button v-if="variantAttribute" variant="primary" @click="onAddNewCat">
                            <i class="icon icon-plus-circle"></i>{{ getCommonText('add') }}
                        </b-button>
                        <b-button
                            variant="success"
                            :disabled="!isDecodesDataChanged || !variantAttribute"
                            @click="prepareForSaveDecode">
                            {{ getCommonText('save') }}
                        </b-button>
                    </template>
                </div>
            </div>
        </div>

        <breadcrumbs-filter v-show="isCategoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="isCategoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering text-center"></td>
                    <td class="td-numbering text-center"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-warning">9</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getDecText('del_all_recs') : getDecText('del_selected_recs')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(decodes)="data">
                    <button
                        class="table-plus"
                        @click="setCurrCat(data.item)"
                        :title="isDecodeCanBeEdit(data.item) ? getDecText('edit_forbidden') : null"
                        :disabled="isDecodeCanBeEdit(data.item)"
                    >
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(comm_object)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <b-form-select v-if="variantAttribute"
                                       size="sm"
                                       class="w-200px"
                                       :disabled="!data.item.checkForDuplicates && catsHaveDoubles"
                                       :title="data.item.comm_object ? data.item.comm_object[`name_${lang}`] : null"
                                       v-model="data.item.comm_object"
                                       @change="onKeyFIeldsChanged(data.item)"
                                       :options="dictObjects"
                        >
                        </b-form-select>
                        <div v-else>{{ data.value[`name_${lng}`] }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText('not_valid') }}</p>
                                <p>{{ getCommonText('exist') }}</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(rate)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="catsHaveDoubles"
                                  v-b-popover.hover.topright="data.item.comm_tarif"
                                  :class="{rate_text_color_red: data.item.rate !== data.item.comm_tarif}"
                                  :value="data.item.rate"
                                  @change="v => {data.item.rate = v; onCatDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'rate', data.item.rate, 3)">
                    </b-form-input>
                    <div v-else 
                        class="text-right"
                        v-b-popover.hover.topright="data.item.comm_tarif"
                        :class="{rate_text_color_red: data.item.rate !== data.item.comm_tarif}"
                    >{{ $n(data.value) }}</div>
                    <p class="spf151-form-val-msg" v-if="variantAttribute && data.item.comm_object && data.item.rate <= 0">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(tariff)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(power)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(correction_factors)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="catsHaveDoubles"
                                  :value="data.item.correction_factors"
                                  @change="v => { data.item.correction_factors = v; onCatDataChanged(data.item)}"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\-?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'correction_factors', data.item.correction_factors, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(note)="data">
                    <div v-if="variantAttribute" :class="{ 'error': ((data.item.note && data.item.note.length > 255)
                    || (data.item.correction_factors !== 0 && data.item.note.trim().length === 0)) }">
                        <div class="note-box">
                            <b-form-input
                                :disabled="catsHaveDoubles"
                                class="text-right"
                                style="min-width: 90%"
                                :value="data.item.note"
                                @change="v => {data.item.note = v; onCatDataChanged(data.item)}"
                                @keyup.enter.exact="keyup13">
                            </b-form-input>
                            <i v-if="variantAttribute"
                                class="icon icon-more thin-blue pointer icon-18 icon-rotate-90" 
                                @click="onEditNote(data.item)">
                            </i>
                        </div>
                        <template v-if="(data.item.note && data.item.note.length > 255)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("len_limit_255") }}</p>
                            </div>
                        </template>
                        <template v-else-if="(data.item.correction_factors !== 0 && data.item.note.trim().length === 0)">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("required") }}</p>
                            </div>
                        </template>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick(getDataForFiles(data.item))"
                    >({{data.item.row_files.length}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="8">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="3"></td>
                </template>
            </b-table>
        </div>
        <template v-if="isDecodeMode">
            <b-overlay :show="decodeData.isDecodeTableOverlay" rounded="sm">
                <budget-forms-template-5
                    ref="formTemplate"
                    :decodeData="decodeData"
                    :variantAttribute="variantAttribute"
                    :selectedCat="selectedCat"
                    :onEditCatName="onEditCatName"
                    :categoryFieldNames="categoryFieldNames"
                    :lng="lng"
                    @setIsAllDelete="setIsAllDelete"
                    @inputFixedVldtn="inputFixedVldtn"
                    @checkForDoubles="checkForDoubles"
                    @deleteDecodes="deleteDecodes"
                    @triggDownloadFile="triggDownloadFile"
                    @triggDownloadAllFiles="triggDownloadAllFiles"
                    @fileUpload="fileUpload"
                ></budget-forms-template-5>
                <div class="table-add" v-if="variantAttribute">
                    <span @click="onAddNewCat"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
                </div>
            </b-overlay>
        </template>
        <div class="table-add" v-if="variantAttribute && isCategoryMode" :disabled="isLoad || catsHaveDoubles">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
        <files-updown ref="fileUpdown"
            v-show="isCategoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @getNewFiles="getNewFiles"
            @delFile="delFile"
            @restetFilesList="restetFilesList"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            :hasFilesTable="true"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
        <c-set-b-f-category-name
            ref="setCatNameModal"
            :budgetForm="decodeData.goodsData"
            :categoryFieldNames="categoryFieldNames"
            :lng="lng"
            :getCommonText="getCommonText"
            @addItem="addGoodItem"
            @onCatNameChanged="onCatNameChanged"
        />
        <modal-note
            ref="setNoteModal"
            :noteFieldName="noteFieldName"
            :getCommonText="getCommonText"
            :onEditNote="onEditNote"
            @onNoteChanged="onNoteChanged"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import Spf151DecodesMixin from "./mixins/spf-151-decodes-mixin.js";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import BudgetFormsTemplate5 from "./components/budget-forms-template5.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";
import ModalNote from './components/modal-note.vue';

export default {
    name: 'Form03_151',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalAllFilesManagement, 
        CBudgetFormsCopyData,
        BudgetFormsTemplate5,
        CSetBFCategoryName,
        ModalNote
    },
    mixins: [FormsHandlerMixin1, Spf151DecodesMixin],
    data() {
        return {
            form: {
                code: '03-151',
                name_ru: 'Расчет расходов на оплату электроэнергии',
                name_kk: 'Электр энергиясына ақы төлеуге жұмсалған шығыстарды есептеу'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            dictObjects: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            energyTarifsBase: [],
            catsHaveDoubles: false,
            categoryFieldNames: {ru: "name_object", kk: "name_object_kk"},
            noteFieldName: 'note'
        };
    },
    async mounted() {
        const promises = [
            this.loadDictObjects(),
            this.loadDictTariffs()
        ]
        await Promise.all(promises);
    },
    watch: {
        isGkkpMode() {
            this.energyTarifsToTree();
        },
        energyTarifsBase() {
            this.energyTarifsToTree();
        }
    },
    methods: {
        addItem() {
            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            this.itemUpdate(item);
            this.defineCalculatedFields(item);
            this.budgetForm.push(item);
            this.checkDoubles();
        },

        checkDoubles() {
            const firstEntryItm = {};
            let areDoubles = false;
            this.budgetForm.forEach((itm, idx) => {
                if (!itm.comm_object === undefined || itm.name_ru === null) itm.name_ru = "";
                const code = itm.comm_object && itm.comm_object.code ? itm.comm_object.code : 'no code';
                if (!firstEntryItm.hasOwnProperty(code)) {
                    firstEntryItm[code] = idx;
                    itm.checkForDuplicates = false;
                } else {
                    itm.checkForDuplicates = true;
                    areDoubles = true;
                    const firsDoubleIdx = firstEntryItm[code];
                    this.budgetForm[firsDoubleIdx].checkForDuplicates = true;
                }
            });
            this.catsHaveDoubles = areDoubles;
        },
        
        onNoteChanged(item) {
            this.onCatDataChanged(item);
        },

        afterDeleted() {
            this.checkDoubles();
        },

        async itemUpdate(item) {
            this.$set(item, 'comm_object', null);
            this.$set(item, 'rate', 0);
            this.$set(item, 'tariff', 0);
            this.$set(item, 'rate_norm', 0);
            this.$set(item, 'power', 0);
            this.$set(item, 'dec_total', 0);
            this.$set(item, 'correction_factors', 0);
            this.$set(item, 'note', '');
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'new_item', true);
            this.$set(item, 'category_id', this.uuidv4());
            this.$set(item, 'row_files', []);
            this.$set(item, 'files', 0);

            // этот атрибут чтобы найти схожий запись по наименованию
            this.$set(item, 'checkForDuplicates', false);

            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },

        getItem(code, list) {
            const item = list.filter(function (row) {
                if (row.value.code === code) { return row; }
            });

            if (item.length > 0) {
                return item[0].value;
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // ввод по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            this.load = true;
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                if (response.status === 200) {
                    values = await response.json();
                    this.prepareBudgetForm(values);
                } else {
                    this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('bad_request')} loadDatas ${response.status}`);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
                return;
            }
            this.load = false;
        },

        prepareBudgetForm(values) {
            const listCheckDuplicates = [];
            this.budgetForm.splice(0);
            values.forEach(val => {
                const item = {
                    id: val.id,
                    comm_object: this.getItem(val.comm_object, this.dictObjects),
                    tariff: val.tariff,
                    rate_norm: val.rate_norm,
                    power: val.power,
                    decodes_id_with_files: val.decodes_id_with_files,
                    row_files: val.row_files,
                    itemToDelete: false,
                    rate: val.rate,
                    files: this.getUnicFilesArr(val),
                    category_id: val.category_id,
                    dec_total: val.dec_total
                };
                item['checkForDuplicates'] = !!listCheckDuplicates.find(el => el === item.comm_object.code)

                val.correction_factors ? this.$set(item, 'correction_factors', val.correction_factors)
                    : this.$set(item, 'correction_factors', 0);
                val.note ? this.$set(item, 'note', val.note)
                    : this.$set(item, 'note', '');

                listCheckDuplicates.push(val.comm_object.code)
                this.defineCalculatedFields(item);
                this.budgetForm.push(item);
            });
        },

        defineCalculatedFields(item) {
            Object.defineProperty(item, 'total', {
                get: () => {
                    const total = item.dec_total + item.correction_factors;
                    return parseFloat((Math.round(total * 1000) / 1000).toFixed(3));
                }
            });
            Object.defineProperty(item, 'comm_tarif', {
                get: () => { 
                    const comm_object = item.comm_object;
                    if (!comm_object || !this.commTarifs[comm_object.code]) return 0;
                    const commTarif = this.commTarifs[comm_object.code]
                    return commTarif || 0;
                }
            });
        },
        async loadDictObjects() {
            try {
                const response = await fetch('/api-py/dictionary/comm_objects/');
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    Object.defineProperty(el, 'text', {
                        get: () => {
                            if (this.lng) return row[`name_${this.lng}`];
                            return row.name_ru;
                        }
                    });
                    if (['1', '2'].includes(row.code)) {
                        this.$set(el, 'disabled', true);
                    }
                    this.dictObjects.push(el);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictObjects`, error.toString());
            }
        },

        async loadDictTariffs() {
            try {
                const response = await fetch('/api-py/dictionary/energy_tariffs/');
                if (response.status === 200) {
                    const items = await response.json();
                    if (this.energyTarifsBase.length) this.energyTarifsBase.splice(0);
                    this.energyTarifsBase.push(...items);
                 }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDictTariffs`, error.toString());
            }
        },

        energyTarifsToTree() {
            const items = this.energyTarifsBase;
            if (!items || !items.length || !this.header) return;
            const structuredTarifs = {}
            const curRegionShrtCode = this.header.region_code.substring(0, 2);
            items.forEach(item => {
                if (item.region_obl === curRegionShrtCode) {
                    const comm_object = item.comm_object;
                    const rate = item.rate;
                    structuredTarifs[comm_object] = rate;
                };
            });
            this.commTarifs = structuredTarifs;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            let error = false;
            const template = (row) => {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'comm_object', row.comm_object.code);
                this.$set(item, 'tariff', parseFloat(row.tariff));
                this.$set(item, 'power', parseFloat(row.power));
                this.$set(item, 'rate', parseFloat(row.rate));
                this.$set(item, 'correction_factors', parseFloat(row.correction_factors));
                this.$set(item, 'note', row.note);
                this.$set(item, 'row_files', row.row_files);
                this.$set(item, 'total', row.total);
                this.$set(item, 'category_id', row.category_id);
                values.push(item);
            }
            for (const row of this.budgetForm) {
                if (this.isCatDataValid(row)) template(row)
                else error = true;
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('not_valid')}. ${this.getErrText('not_saved')}`);
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values, error);
                }
                else {
                    this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
                }
            }
        }, // подготовка к сохранению

        isCatDataValid(row) {
            if (!(row.comm_object === null || row.rate === 0)) {
                if (row.correction_factors === 0 && (row.note === null || row.note.length < 256)) {
                    return true;
                } else if (row.correction_factors !== 0 && row.note.trim().length !== 0 && row.note.length < 256) {
                    return true;
                } else {
                    return false;
                }
            } 
            else return false;
        },

        async save(values, error) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
        
        setRate(item) {
            if (item.new_item) this.$set(item, 'rate', item.comm_tarif);
        },

        onKeyFIeldsChanged(item) {
            this.checkDoubles();
            this.setRate(item);
            this.onCatDataChanged(item);
        },

        onCatDataChanged(item) {
            this.$set(item, 'changed', true);
        }, 

        isDecodeCanBeEdit(item) {
            return !item.comm_object || this.catsHaveDoubles || !this.isCatDataValid(item)
        },

        getDataForFiles(item) {
            const objectName = item.comm_object ? item.comm_object[`name_${this.lng}`] : ''; 
            return {...item, name_ru: objectName}
        }, 

        onAllFilesClick(item) {
            this.allFiles = item.hasOwnProperty('row_files') ? item.row_files : [];
            this.$refs.modalAllFilesManagement.showModal(item);
        },

        delFile(file_id) {
            this.budgetForm.forEach(item => {
                item.row_files = item.row_files.filter(file => file.file_id !== file_id);
                item.files = item.row_files.length;
            });
        },

        getNewFiles(files) {
            if (!files.length || !this.$refs.formTemplate) return;
            this.decodeData.goodsData.forEach(item => {
            files.forEach((file) => {
                const newFile = JSON.parse(JSON.stringify(file));
                const isNotSelectedRow = file.row_id !== item.id;
                if (isNotSelectedRow) {
                    newFile.row_id = null;
                    item['row_files'] = [...item['row_files'], newFile];
                }
            })
            })
            this.$refs.formTemplate.onNewFileAdded(files);
        },

        restetFilesList() {
            // не удалять
        }

    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        },
        isDecodesDataChanged() {
            return this.decodeData.goodsData.findIndex(itm => itm.changed === true) !== -1;
        },
        isCatDataChanged() {
            return this.budgetForm.findIndex(itm => itm.changed === true) !== -1;
        },

        getFormText() {
            return this.setFormText('form_n_151.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'decodes',
                        label: ' '
                    },
                    {
                        key: 'comm_object',
                        label: this.getFormText('comm_object')
                    },
                    {
                        key: 'rate',
                        label: this.getFormText('kind_norm')
                    },
                    {
                        key: 'tariff',
                        label: this.getFormText('rate')
                    },
                    {
                        key: 'rate_norm',
                        label: this.getFormText('rate_norm_decode')
                    },
                    {
                        key: 'power',
                        label: this.getFormText('power_unit_count')
                    },
                    {
                        key: 'correction_factors',
                        label: `${this.getFormText('correction_sum')}  (+/- ${this.getUnitsText('thousand_tenge')})`
                    },
                    {
                        key: 'total',
                        label: `${this.getCommonText('spnds_sum')} (${this.getUnitsText('group')}5 х ${this.getUnitsText('group')}6)/1000`
                    },
                    {
                        key: 'note',
                        label: this.getCommonText('notes')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>
<style scoped>
    #goods-modal-header-i {
        cursor: pointer;
    }
    .filter-actions-flex {
        display: flex;
    }
    .table-plus {
        border: none;
    }
    .back-to-category-mode-btn {
        min-width: 172px;
    }
    .rate_text_color_red {
        color: red !important;
    }
    .spf151-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .note-box {
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .icon-more {
        align-self: center;
        position: static;
    }
</style>